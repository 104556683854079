.orgchart.myChart {
  max-width: 100%;
  overflow-x: scroll;
  background-image: linear-gradient(
      90deg,
      rgba(33, 90, 136, 0.15) 0%,
      rgba(0, 0, 0, 0) 0%
    ),
    linear-gradient(rgba(33, 90, 136, 0.15) 0%, rgba(0, 0, 0, 0) 0%) !important;
}

.orgchart.myChart > ul > li > ul li::before {
  border-top-color: #3f51b5;
}

.orgchart.myChart > ul > li > ul li .oc-node::before,
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
  background-color: #444;
}

.orgchart-container {
  box-sizing: border-box;
  position: relative;
  margin: 5px;
  height: 700px;
  background-color: #fff;
  border: 0px dashed #aaa;
  border-radius: 5px;
  overflow: auto;
  text-align: center;
}

.orgchart.myChart .oc-node .position {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #b9262d !important;
  color: #fff;
  width: 100px;
  height: 45px;
}

.orgchart.myChart .oc-node .fullname {
  box-sizing: border-box;
  color: #b9262d;
  background-color: #fff;
  width: 100px;
  font-size: 8pt;
  height: 45px;
  padding: 4px;
  border: 2px solid #444;
}
